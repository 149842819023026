import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { exchangeCodeForToken } from '@oneecosystem/authenticate';
import { useAuth } from '../../../components/UI';

const RedirectCallback = ({ history }) => {
  const { fetchUserData } = useAuth();

  useEffect(() => {
    handleSuccessLogin();
  }, []);

  const handleSuccessLogin = async () => {
    await exchangeCodeForToken();
    await fetchUserData();
    history.push('/dashboard');
  };

  return null;
};

RedirectCallback.propTypes = {
  fulfillUser: PropTypes.func,
  history: PropTypes.object,
};

export default RedirectCallback;
