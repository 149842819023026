import { useState, useContext, useEffect, createContext } from 'react';
import PropTypes from 'prop-types';
import { omit, isArray } from 'lodash-es';
import { Route, matchPath } from 'react-router-dom';
import { renewSession, softLogout, getToken } from '@oneecosystem/authenticate';
import { getMyProfile } from '../../../services/profiles';
import { getMyPackages } from '../../../services/packages';
import { postNewAcademyAccount, getNewAcademyAccount } from '../../../services/accounts';

const AuthContext = createContext({});
export const useAuth = () => useContext(AuthContext);

const AuthProvider = (props) => {
  const { children, renderRedirect, renderLogout } = props;
  const [user, setUser] = useState({ isAuthenticated: !!getToken() });

  useEffect(() => {
    user?.isAuthenticated
      ? fetchUserData()
      : !matchPath(window.location.pathname, '/authorization-callback') &&
        setUser({ loaded: true, isAuthenticated: false });
  }, []);

  useEffect(() => {
    if (user?.loaded) document.getElementById('splash-screen').style.display = 'none';
  }, [user?.loaded]);

  const fetchUserData = async () => {
    await renewSession();
    const [profileData, err1] = await getMyProfile();
    const [packagesData, err2] = await getMyPackages();
    if (err1 || err2) return softLogout();

    const currentAccount = profileData.accounts.find((el) => el.isCurrent === true);

    const [academyAccount, err3] = await academyAccountSet(currentAccount?.id);

    if (academyAccount && !err3) {
      setUser({
        loaded: true,
        isAuthenticated: true,
        profile: profileData,
        currentAccount,
        myPackages: packagesData,
        academyAccount,
      });
    } else softLogout();
  };

  const academyAccountSet = async (id) => {
    const [res1, err1] = await getNewAcademyAccount([id]);

    if (res1) return [res1, err1];

    const [res2, err2] = await postNewAcademyAccount([id]);

    return [isArray(res2?.success) && res2.success.find((el) => el.externalAccountId === id), err2];
  };

  const changeAccount = async (value) => {
    const [packagesData, err] = await getMyPackages();
    const [academyAccount, err3] = await academyAccountSet(value?.id);

    if (academyAccount && packagesData && !err && !err3) {
      setUser((prev) => ({
        ...prev,
        currentAccount: value,
        myPackages: packagesData,
        academyAccount,
      }));
    } else softLogout();
  };

  const updateMyPackages = async (value) => {
    setUser((prev) => ({ ...prev, myPackages: value }));
  };

  const userData = omit(user, ['loaded', 'isAuthenticated']);

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated: user.isAuthenticated,
        user: userData,
        setUser,
        changeAccount,
        updateMyPackages,
        fetchUserData,
      }}>
      {renderRedirect && <Route path="/authorization-callback" component={renderRedirect} />}
      {renderLogout && <Route path="/logout" component={renderLogout} />}
      {user?.loaded && children}
    </AuthContext.Provider>
  );
};

AuthProvider.propTypes = {
  children: PropTypes.node,
  renderRedirect: PropTypes.func,
  renderLogout: PropTypes.func,
};

export default AuthProvider;
